.filterButton {
  color: var(--primary) !important;
  height: 50px;
  margin-left: 20px !important;
}

.filterArea {
  padding: 10px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.filterArea label {
  width: 150px;
}

.addFilter {
  font-size: 13px !important;
}

.filter {
  width: 100%;
}

.filter .MuiOutlinedInput-root {
  height: 40px;
  margin-bottom: 5px;
}

.filterIcon {
  display: block !important;
}

@media (max-width: 600px) {
  .filterButton {
    margin-left: 5px !important;
  }

  .filterIcon {
    display: none !important;
  }
}